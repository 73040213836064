<template>
  <section class="favorites">
    <h4 class="section-title mb-4">Избранные товары</h4>
    <div v-if="favorites && favorites.length" class="favorites">
      <b-row>
        <b-col v-for="product in favorites" :key="product.id" class="mb-4" md="3" sm="6">
          <ProductCard :product="product" show-favorite-icon />
        </b-col>
      </b-row>
    </div>
    <div v-else class="favorites__empty text-center">В избранном списке отсутствуют товары</div>
  </section>
</template>

<script>
import Api from '@/services/api'
import ProductCard from '@/components/Product/ProductCard'

export default {
  name: 'FavoritesPage',
  components: {
    ProductCard,
  },

  computed: {
    isLogged() {
      const token = this.$store.state.token
      if (token) return true
      const lsToken = localStorage.getItem('uni_token')
      return !!lsToken
    },

    favorites() {
      return this.$store.state.products.favorites
    },
  },

  async mounted() {
    if (!this.isLogged) {
      let favorites = JSON.parse(localStorage.getItem('uni_favorites'))
      if (!favorites || !Array.isArray(favorites)) favorites = []
      this.$store.commit('products/SET_FAVORITE_PRODUCTS', favorites)
    } else {
      const { favorites } = await this.wrapAndHandleRequest(Api.fetchFavoriteList, true)
      this.$store.commit('products/SET_FAVORITE_PRODUCTS', favorites)
    }
  },
}
</script>
